import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";

export default function AltaDaSelicEndividamentoNoBrasil() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_ALTA_DA_SELIC_E_O_ENDIVIDAMENTO_NO_BRASIL_COMO_ORGANIZAR_SUAS_FINANCAS_E_EVITAR_A_INADIMPLENCIA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <Container className="sessao-post">
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
          <Grid
            className="logo-blog"
            size={12}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/blog_alta_da_selic_e_o_endividamento_no_brasil.png"
              alt="logo-blog"
            />
          </Grid>

          <Grid size={12}>
            <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
              Alta da Selic e o endividamento no Brasil: Como organizar suas
              finanças e evitar a inadimplência
            </Typography>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ fontSize: 22 }} gutterBottom>
              O aumento da taxa Selic para 13,75% ao ano tem gerado uma onda de
              preocupações entre os brasileiros. Essa elevação torna o crédito
              mais caro, impactando diretamente a capacidade de pagamento de
              contas essenciais como aluguel, energia, e até mensalidades
              escolares. Confira este artigo e descubra como organizar suas
              finanças e evitar a inadimplência!
            </Typography>
            <Typography sx={{ fontSize: 22 }}>
              A inadimplência está em alta, e mais de 70 milhões de brasileiros
              enfrentam o problema de ter o nome negativado. Em um cenário
              econômico desafiador, é fundamental buscar alternativas para
              organizar as finanças e evitar o endividamento.
            </Typography>
          </Grid>

          <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                Como a alta da Selic afeta o seu orçamento
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20, mb: 2 }}>
                Com as taxas de juros mais altas, o crédito no Brasil se torna
                mais caro. Para muitos, isso significa pagar mais por
                empréstimos, cartões de crédito e financiamentos. As parcelas
                aumentam, e muitas pessoas acabam se endividando sem conseguir
                honrar seus compromissos. Isso pode gerar multas, juros altos e,
                no caso de inadimplência, o nome fica no vermelho, o que
                dificulta a obtenção de crédito no futuro. E ainda, o aumento da
                Selic também pode afetar o poder de compra da população,
                deixando as finanças de muitas famílias em um estado de alerta.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                Alternativas para organizar suas finanças
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20 }}>
                Diante desse cenário, buscar soluções financeiras que ofereçam
                mais flexibilidade e segurança é crucial. Uma das alternativas
                para quem precisa de mais controle sobre as despesas é o
                parcelamento de boletos e PIX no cartão de crédito. Essa solução
                permite que você pague suas contas em até 12 vezes, sem a
                necessidade de um novo financiamento ou empréstimo pessoal com
                juros elevados.
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                O parcelamento de boletos e PIX no cartão de crédito pode ser
                uma opção para quem precisa equilibrar o orçamento de forma
                inteligente. Além de evitar atrasos, ele ajuda a manter as
                finanças organizadas, protegendo seu nome e evitando o
                comprometimento do fluxo de caixa mensal.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                Quita Boletos: Uma solução segura e acessível{" "}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20 }}>
                A <span className="quita">Quita Boletos</span> oferece a
                possibilidade de parcelar boletos e PIX diretamente no cartão de
                crédito, sem complicação e de maneira rápida. Essa solução não
                só ajuda a evitar a inadimplência, como também garante mais
                controle sobre o seu orçamento.
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                Em 2024, a <span className="quita">Quita Boletos</span> ajudou
                mais de 280 mil pessoas a resolverem suas pendências
                financeiras, com milhões de reais em débitos parcelados. O
                processo é simples, transparente e acessível, permitindo que
                cada cliente encontre a melhor forma de organizar suas finanças,
                sem se preocupar com taxas ocultas ou burocracia.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, mb: 1 }}>
                Como a Quita Boletos pode te ajudar a organizar seu orçamento
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20, mb: 1 }}>
                Com a <span className="quita">Quita Boletos</span>, você tem
                acesso a uma plataforma segura e eficiente para parcelar suas
                contas. Ao utilizar o limite do seu cartão de crédito, você
                ganha mais flexibilidade, podendo dividir suas despesas em até
                12 vezes. Isso não só evita que você utilize o chegue especial,
                como também ajuda a proteger seu nome no mercado. A solução é
                ideal para quem quer manter as contas em dia e organizar o
                orçamento sem complicação.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, mb: 1 }}>
                O impacto da alta da Selic e a importância de soluções flexíveis
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20, mb: 1 }}>
                Com aumento da Selic, o parcelamento de contas se torna uma
                alternativa ainda mais relevante para quem busca estabilidade
                financeira. Utilizando plataformas como a Quita Boletos, é
                possível organizar o orçamento com mais controle e segurança,
                sem a pressão dos juros abusivos.
              </Typography>
              <Typography sx={{ fontSize: 20, mb: 1 }}>
                Se você está buscando uma maneira de colocar suas contas em dia
                e evitar a inadimplência, não deixe de realizar uma simulação de
                parcelamento com a <span className="quita">Quita</span>. Com um
                processo transparente e flexível, você poderá organizar suas
                finanças e alcançar mais tranquilidade financeira.
              </Typography>
            </Grid>
            <Grid size={12} align="center">
              <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";

export default function PixCartaoDeCreditoSaibaComoDividirSeusPagamentos() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_PIX_CARTAO_DE_CREDITO_SAIBA_COMO_DIVIDIR_SEUS_PAGAMENTOS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <Container className="sessao-post">
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
          <Grid
            className="logo-blog"
            size={12}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/blog_alta_da_selic_e_o_endividamento_no_brasil.png"
              alt="logo-blog"
            />
          </Grid>

          <Grid size={12}>
            <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
              Pix com cartão de crédito: saiba como dividir seus pagamentos em
              até 12x
            </Typography>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ fontSize: 22, fontStyle: "italic" }} gutterBottom>
              Em 2023, o Pix consolidou-se como o meio de pagamento mais
              utilizado no Brasil, registrando quase{" "}
              <strong>42 bilhões de transações</strong>, um aumento de 75% em
              relação ao ano anterior. Em 2024, 76,4% da população brasileira
              utilizou o Pix, superando o uso de dinheiro em espécie, que foi de
              68,9%.
            </Typography>
            <Typography sx={{ fontSize: 22 }} gutterBottom>
              O <strong>Pix</strong> revolucionou as transações financeiras no
              Brasil, oferecendo pagamentos instantâneos. Agora, com o Pix
              parcelado, essa facilidade vai além, permitindo que você utilize o
              limite do seu cartão de crédito para parcelar pagamentos via Pix
              em até 12 vezes. Com a <span className="quita">Quita</span>, essa
              funcionalidade é ainda mais flexível, possibilitando o uso de mais
              de um cartão para realizar o parcelamento. Quer saber como
              parcelar seu Pix no cartão de crédito? Leia agora:
            </Typography>
          </Grid>

          <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                O que é o Pix Parcelado?
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20, mb: 2 }}>
                Pix parcelado é uma modalidade que permite transformar o limite
                do seu cartão de crédito em um pagamento via Pix, com a opção de
                parcelar o valor em até 12 vezes. Esse formato é ideal para quem
                deseja realizar uma compra ou pagamento, mas prefere dividir o
                valor em parcelas que cabem no bolso e não pesam no orçamento do
                mês.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                Como funciona o Pix parcelado na Quita?
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 20 }}>
                O processo é simples e descomplicado:
              </Typography>
              <ol className="lista">
                <li>
                  <strong>Escolha o pagamento:</strong> selecione o Pix que
                  deseja pagar.
                </li>
                <li>
                  <strong>Selecione o cartão de crédito:</strong> utilize um ou
                  mais cartões de crédito para compor o valor total da operação.
                </li>
                <li>
                  <strong>Defina o parcelamento:</strong> escolha em quantas
                  parcelas deseja dividir o pagamento, podendo ser em até 12
                  vezes.
                </li>
                <li>
                  <strong>Finalize o pagamento:</strong> com o limite disponível
                  no(s) cartão(ões), a Quita realiza o pagamento à vista do seu
                  Pix, enquanto você quita as parcelas conforme definido na
                  simulação
                </li>
              </ol>

              <Typography sx={{ fontSize: 20, mt: 2 }}>
                Assim, você pode administrar pagamentos de maior valor com mais
                facilidade e aproveitar melhor o limite disponível em múltiplos
                cartões.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                Vantagens de utilizar o Pix parcelado:
              </Typography>
            </Grid>
            <Grid size={12}>
              <ol className="lista">
                <li>
                  <strong>Flexibilidade de pagamento:</strong> parcele seus
                  pagamentos em até 12 vezes, ajustando as parcelas ao seu
                  orçamento mensal.
                </li>
                <li>
                  <strong>Uso de múltiplos cartões:</strong> combine os limites
                  de mais de um cartão de crédito para efetuar pagamentos
                  maiores.
                </li>
                <li>
                  <strong>Praticidade e agilidade:</strong> realize todo o
                  processo de forma online, sem burocracias, com aprovação
                  rápida.
                </li>
              </ol>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, mb: 1 }}>
                Como começar a usar o Pix parcelado?
              </Typography>
            </Grid>
            <Grid size={12}>
              <ol className="lista">
                <li>
                  <strong></strong> visite o site da{" "}
                  <span className="quita">Quita</span> -
                  <strong>quitaboletos.com.br</strong>
                </li>
                <li>
                  <strong>Personalize a simulação:</strong> insira os dados do
                  Pix que deseja realizar e escolha o parcelamento que melhor se
                  adapta ao seu orçamento. E aproveite o cupom SITEQB5 para
                  garantir 5% de desconto no seu parcelamento!
                </li>
                <li>
                  <strong>Praticidade e agilidade:</strong> realize todo o
                  processo de forma online, sem burocracias, com aprovação
                  rápida.
                </li>
              </ol>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 20, mb: 1 }}>
                Com a <span className="quita">Quita</span>, você transforma o
                limite do seu cartão de crédito em uma ferramenta prática para
                parcelar seus pagamentos, garantindo flexibilidade e segurança.
                Manter suas contas em dia fica mais simples, com mais controle e
                tranquilidade no seu dia a dia.
              </Typography>
            </Grid>
            <Grid size={12} align="center">
              <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

import { useNavigate } from "react-router-dom";
import { IconButton, Link } from "@mui/material";
import { abreSimulador, direcionaParaApp } from "@/services/utils";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Logo from "@/components/Logo";
import "./index.scss";

function Rodape() {
  const navigation = useNavigate();

  return (
    <Container className="sessao-site sessao-rodape" component="footer">
      <Grid container sx={{ p: 3 }} justifyContent="center">
        <Grid size={{ xs: 12, sm: 3 }} sx={{ mb: { xs: 2, md: 0 } }}>
          <Typography className="titulos">QUITA BOLETOS</Typography>

          <Typography className="item-menu">
            <Link onClick={() => navigation("/sobre-nos")}>Sobre nós</Link>
          </Typography>
          <Typography className="item-menu">
            <Link onClick={() => navigation("/como-funciona")}>Como usar</Link>
          </Typography>
          <Typography className="item-menu">
            <Link
              onClick={() => {
                window.location.href = "https://www.quitapay.com";
              }}>
              Parceiros
            </Link>
          </Typography>
          <Typography className="item-menu">
            <Link onClick={() => navigation("/termos-de-uso")}>
              Termos e Condições
            </Link>
          </Typography>
          <Typography className="item-menu">
            <Link
              onClick={() =>
                window.open("/politica-privacidade.html", "_blank")
              }>
              Política de Privacidade
            </Link>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }} sx={{ mb: { xs: 2, md: 0 } }}>
          <Typography className="titulos">PRODUTOS</Typography>

          <Typography className="item-menu">
            <Link onClick={() => direcionaParaApp(`/app/login`)}>Entrar</Link>
          </Typography>
          <Typography className="item-menu">
            <Link onClick={() => abreSimulador()} sx={{ fontWeight: "bold" }}>
              Simular
            </Link>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }} sx={{ mb: { xs: 2, md: 0 } }}>
          <Typography className="titulos">EXPLORE</Typography>

          <Typography className="item-menu">
            <Link onClick={() => navigation("/perguntas")}>Perguntas</Link>
          </Typography>
          <Typography className="item-menu">
            <Link onClick={() => navigation("/blog")}>Blog</Link>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }} sx={{ mb: { xs: 2, md: 0 } }}>
          <Typography className="titulos">CONTATO</Typography>

          <Typography className="item-menu">
            contato@quitaboletos.com.br
          </Typography>
          <Typography className="item-menu">(55) 9 9904-2235</Typography>
        </Grid>

        <Grid
          size={{ xs: 12, md: 4 }}
          sx={{ mt: 2, textAlign: { xs: "center", md: "left" } }}>
          <Logo />
        </Grid>

        <Grid
          size={{ xs: 12, md: 4 }}
          sx={{ mt: 2, textAlign: { xs: "center", md: "left" } }}>
          <Typography className="endereco">
            2025 Quita Tecnologia LTDA. 42.426.994/0001-52
          </Typography>
          <Typography className="endereco">
            Av Dahne de Abreu, 2700 - Distrito Industrial II - Horizontina, RS -
            98920-000 - Brasil
          </Typography>
        </Grid>

        <Grid
          size={{ xs: 12, md: 4 }}
          sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container>
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                my: { xs: 4, md: 2 },
              }}>
              <IconButton
                title="Whatsapp"
                onClick={() => {
                  window.open(
                    "https://api.whatsapp.com/send?phone=5555999042235&text=Ol%C3%A1. Preciso de ajuda!"
                  );
                }}>
                <img src="/whatsapp.png" alt="whatsapp-logo" />
              </IconButton>
            </Grid>

            <Grid size={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Grid className="redes-sociais">
                <IconButton
                  title="Instagram"
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/quitaboletos/",
                      "_blank"
                    );
                  }}>
                  <img
                    src="/site/instagram-logo.svg"
                    width="28px"
                    height="28px"
                    alt=""
                  />
                </IconButton>
                <IconButton
                  title="Facebook"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/QuitaBoletos/",
                      "_blank"
                    );
                  }}>
                  <img
                    src="/site/facebook-logo.svg"
                    width="30px"
                    height="30px"
                    alt=""
                  />
                </IconButton>

                <IconButton
                  title="TikTok"
                  sx={{}}
                  onClick={() => {
                    window.open(
                      "https://www.tiktok.com/@quitaboletosof",
                      "_blank"
                    );
                  }}>
                  <img
                    src="/site/tiktok-logo.svg"
                    width="50px"
                    height="50px"
                    alt=""
                  />
                </IconButton>
                <IconButton
                  title="X"
                  sx={{}}
                  onClick={() => {
                    window.open("https://x.com/quitaboletos", "_blank");
                  }}>
                  <img
                    src="/site/x-logo.svg"
                    alt=""
                    width="25px"
                    height="25px"
                  />
                </IconButton>
                <IconButton
                  title="YouTube"
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/channel/UCMDBfomX_bSscVXL9Waj4dw",
                      "_blank"
                    );
                  }}>
                  <img
                    src="/site/youtube-logo.svg"
                    alt=""
                    width="30px"
                    height="30px"
                  />
                </IconButton>
                <IconButton
                  title="Linkedin"
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/quita-boletos/",
                      "_blank"
                    );
                  }}>
                  <img
                    src="/site/linkedin-logo.svg"
                    alt=""
                    width="30px"
                    height="30px"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mb: 2, p: 1 }}>
        <Grid size={12} align="center">
          <Typography>
            Em acordo as diretrizes do BACEN, nos termos da Resolução n° 3.954
            de 24 de fevereiro de 2011.
          </Typography>
        </Grid>
        <Grid size={12} align="center">
          <Typography>Somos correspondentes bancários autorizados.</Typography>
        </Grid>
        <Grid size={12} align="center">
          <Typography>
            VIACERTA FINANCIADORA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO,
            instituição financeira inscrita no CNPJ 05.192.316/0001-46.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Rodape;

import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";

export default function EducacaoFinanceiraOCaminhoParaUmaVidaComQualidade() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_EDUCACAO_FINANCEIRA_O_CAMINHO_PARA_UMA_VIDA_COM_MAIS_QUALIDADE",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_educacao_financeira_o_caminho_para_uma_vida_com_mais_qualidade.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                Educação Financeira: O caminho para uma vida com mais qualidade
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography
                sx={{ fontSize: 22, fontStyle: "italic" }}
                gutterBottom>
                A educação financeira desempenha um papel fundamental na
                transformação de hábitos e na promoção de uma vida mais
                equilibrada e tranquila. Ao adquirir conhecimentos sobre como
                gerenciar recursos financeiros, as pessoas podem tomar decisões
                mais conscientes, evitando gastos impulsivos e prevenindo o
                endividamento excessivo.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Entenda os benefícios da Educação Financeira:
                </Typography>

                <ol className="list">
                  <li>
                    <strong>Controle de gastos: </strong>
                    Monitorar despesas permite identificar e eliminar gastos
                    desnecessários, direcionando recursos para prioridades
                    reais.
                  </li>
                  <li>
                    <strong>Planejamento e poupança: </strong> Estabelecer metas
                    financeiras e criar um plano de poupança possibilita a
                    realização de sonhos, como viagens, aquisição de bens ou
                    investimentos em educação.
                  </li>
                  <li>
                    <strong>Redução do estresse: </strong>
                    Uma vida financeira organizada diminui preocupações com
                    dívidas, contribuindo para o bem-estar mental e emocional.
                  </li>
                  <li>
                    <strong>Tomada de decisões conscientes: </strong>
                    Com conhecimento financeiro, é possível fazer escolhas mais
                    informadas, evitando armadilhas como juros altos e
                    endividamento excessivo.
                  </li>
                </ol>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Implementar a educação financeira no dia a dia requer
                  disciplina e comprometimento, mas os resultados são
                  recompensadores. Ao adotar práticas financeiras saudáveis, as
                  pessoas podem alcançar maior estabilidade econômica e,
                  consequentemente,{" "}
                  <strong>uma melhor qualidade de vida.</strong>
                </Typography>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Além disso, empresas que investem em programas de educação
                  financeira para seus colaboradores observam benefícios
                  significativos, como{" "}
                  <strong>
                    aumento da produtividade e redução do estresse
                  </strong>{" "}
                  relacionado a questões financeiras.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Portanto, a educação financeira não apenas capacita indivíduos
                  a gerenciar melhor seus recursos, mas também contribui para o
                  desenvolvimento de uma sociedade mais consciente e equilibrada
                  financeiramente.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Para ajudar nessa jornada, a{" "}
                  <span className="quita">Quita Boletos</span> é a parceira
                  ideal, oferecendo soluções práticas e acessíveis para
                  organizar suas contas. Com a opção de parcelar boletos e Pix
                  em até 12x no cartão de crédito, a{" "}
                  <span className="quita">Quita</span> permite que você mantenha
                  suas finanças em dia sem comprometer o orçamento. É a
                  alternativa perfeita para quem busca agilidade, segurança e
                  flexibilidade no gerenciamento de dívidas, promovendo mais
                  tranquilidade e qualidade de vida.
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Comece hoje mesmo a transformar sua relação com o dinheiro e
                  desfrute de uma vida financeira mais saudável!
                </Typography>
              </Grid>
              <Grid size={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
